import type { ContentType } from '#types/content'
import type { CollectionSettings } from '#types/components/cms/collection'

interface CollectionConfig {
  /**
   * Title style classes (needs to be defined for every collection which is using title)
   */
  titleStyleClasses?: string
  /**
   * Item classes to style items from collection
   */
  itemClass?: string
  /**
   * Carousel classes to style vf-carousel component root
   */
  carouselClasses?: string
  contextColors?: {
    holiday: {
      mainBg: string
      title: string
      text: string
      textFilled: string
      hover: string
    }
    standard: {
      mainBg: string
      title: string
      text: string
      textFilled: string
      hover: string
    }
  }
  /**
   * Property name for items collection
   * NOTE: added because of CMS limitation with naming collection items - each collection needs to have unique name
   */
  itemsProp?: string
  /**
   * Collection settings
   */
  config?: CollectionSettings
}

/**
 * For any specific component, you can define specific settings here to avoid creation of a new component
 * @example
 * import componentConfigMap from './config'
 *
 * const componentConfig = componentConfigMap[content.type!]
 * if (componentConfig) { // do something }
 */
export default {
  VfCanvasLinkedCardCollection: {
    carouselClasses: 'md:mx-12',
    contextColors: {
      holiday: {
        mainBg: '#FFFCF0',
        title: '#000',
        text: '#000',
        textFilled: '#000',
        hover: '#931C16'
      },
      standard: {
        mainBg: '#f8f8f8',
        title: '#525252',
        text: '#cf780f',
        textFilled: '#000',
        hover: '#cf780f'
      }
    }
  },
  VfCanvasCategoryCardCollection: {
    itemsProp: 'categoryCards',
    titleStyleClasses: 'mb-4 ~md:mb-6',
    carouselClasses: 'md:mx-12'
  },
  VfCanvasCTAButtonCollection: {
    itemClass: 'full',
    titleStyleClasses: 'mb-4 md:mb-6'
  },
  VfCanvasMultilinkCollection: {
    titleStyleClasses: 'mb-4'
  }
} as Record<ContentType, CollectionConfig>
